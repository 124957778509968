.content {
  min-width: 1280px;
  max-width: 1280px;
  margin: auto;
}

.header {
  position: relative;
  background-color: #000;
  height: 32px;
  line-height: 32px;
  padding: 0 185px;
}

.bubble {
  display: none;
  position: absolute;
  right: 139px;
  top: 35px;
  width: 215px;
  padding: 10px 25px;
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  font-size: 12px;
  z-index: 999;
  margin: 0;
  line-height: 20px;
}

.bubble::after {
  content: "";
  position: absolute;
  right: 70px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}

.bubble span {
  font-weight: 700;
  color: rgb(58, 186, 236) !important;
  margin: 0 !important;
}

.contact:hover .bubble {
  display: block;
}

.bubble:hover {
  display: block;
  cursor: default;
}

.title {
  color: #fff;
}

.logo {
  width: 78px;
  vertical-align: middle;
}

.left {
  float: left;
}

.right {
  float: right;
  color: #fff;
}

.right a {
  margin-left: 40px;
  font-size: 12px;
  /* cursor: pointer; */
  color: #fff;
  text-decoration: none;
}

.main div {
  position: relative;
  width: 1280px;
  height: 640px;
  background-color: #fff;
}

.main div:nth-child(1) {
  vertical-align: middle;
  background: url("./images/background.png") no-repeat;
  background-size: 100%;
  height: 620px;
}

.cldImg-1 {
  position: absolute;
  width: 384px;
  left: 280px;
  bottom: 0;
}

.cld-1 p {
  position: absolute;
  right: 256px;
  top: 206px;
  font-size: 28px;
  color: #fff;
  font-weight: 700;
}

.apple {
  position: absolute;
  width: 108px;
  right: 423px;
  bottom: 232px;
}

.google {
  position: absolute;
  width: 108px;
  right: 302px;
  bottom: 232px;
}

.cld-2 {
  background: url("./images/img1.png") no-repeat;
  background-size: 1280px;
}

.cld-3 {
  background: url("./images/img2.png") no-repeat;
  background-size: 1280px;
}
.cld-4 {
  background: url("./images/img3.png") no-repeat;
  background-size: 1280px;
}

.footer {
  height: 132px;
  line-height: 132px;
  text-align: center;
}

.footer p {
  font-size: 12px;
  color: #9e9ea4;
}
